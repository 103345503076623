<template>
  <div class="ftpServer">
    <div class="searchCondition">
      <div class="searchConditionItem">
        <span>名称：</span>
        <a-input
          placeholder="请输入"
          v-model.trim="name"
          @pressEnter="query(1)"
          allowClear
          @change="allowClearChange"
        />
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
        <a-button type="primary" @click="add" icon="plus">新增</a-button>
      </div>
    </div>
    <a-table
      bordered
      :rowClassName="$common.rowClassColor"
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="tablePagination"
      :loading="loadingTable"
      size="small"
      :rowKey="(record) => record.id"
    >
      <span slot="password" slot-scope="text, record">
        **********
        <a-button
          type="link"
          style="margin-left: 20px"
          @click="checkPassword(record)"
          >查看</a-button
        >
      </span>
      <span slot="enabled" slot-scope="text, record">
        <a-switch
          checked-children="已启用"
          un-checked-children="已禁用"
          v-model="text"
          @change="ftpServerEnable(record)"
        />
      </span>
      <span slot="action" slot-scope="text, record">
        <a href="javascript:;" @click="modifyClick(record)">修改</a>
        <a-divider type="vertical" />
        <a href="javascript:;" @click="connectTest(record)">测试连接</a>
        <a-divider type="vertical" />
        <a-popconfirm
          placement="right"
          okText="确认"
          cancelText="取消"
          @confirm="deleteClick(record)"
        >
          <template slot="title">是否确认删除{{ record.name }}</template>
          <a href="javascript:;" style="color: #ff4d4f">删除</a>
        </a-popconfirm>
      </span>
    </a-table>

    <a-modal
      :title="title"
      v-model="addKeyVisible"
      :maskClosable="false"
      class="action-class"
      width="500px"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <template v-if="!checkPasswordShow">
          <a-form-item label="名称:" required>
            <a-input v-model="updateData.name" placeholder="请输入名称" />
          </a-form-item>
          <a-form-item label="类型:" required>
            <a-select
              v-model="updateData.type"
              placeholder="请选择类型"
              @change="ftpTypeChange"
            >
              <a-select-option
                v-for="item in ftpTypeList"
                :key="item.value"
                :value="item.value"
              >
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="初始路径:" required>
            <a-input
              v-model="updateData.initialPath"
              placeholder="请输入初始路径"
            />
          </a-form-item>
          <a-form-item label="主机:" required>
            <a-input v-model="updateData.host" placeholder="请输入host" />
          </a-form-item>
          <a-form-item label="端口:" required>
            <a-input v-model="updateData.port" placeholder="请输入端口" />
          </a-form-item>
          <a-form-item label="用户名:" required>
            <a-input
              v-model="updateData.username"
              placeholder="请输入username"
            />
          </a-form-item>
        </template>
        <a-form-item label="密码:" required>
          <a-input
            v-model="updateData.password"
            placeholder="请输入password"
            :disabled="checkPasswordShow"
          />
        </a-form-item>
        <a-form-item label="备注:" v-if="!checkPasswordShow">
          <a-textarea
            v-model="updateData.remark"
            placeholder="请输入备注"
            autoSize
          />
        </a-form-item>
        <a-form-item>
          <span slot="label">
            过期时间
            <a-tooltip placement="left">
              <div slot="title">
                假设设置过期时间为n，文件则会在n天后自动被删除(第一次被扫描后开始计时)
              </div>
              <a-icon
                type="question-circle"
                style="color: #1879ff; font-size: 14px"
              />
            </a-tooltip>
          </span>
          <a-select v-model="updateData.expireAt" placeholder="请选择过期时间">
            <a-select-option
              v-for="item in expireAtList"
              :key="item.id"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="被动模式:" v-if="updateData.type == 'ftp'">
          <a-checkbox v-model="updateData.enterLocalPassiveMode" />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addKeyVisible = false">
            {{ checkPasswordShow ? "关闭" : "取消" }}
          </a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="add_submit"
            v-if="!checkPasswordShow"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
export default {
  name: "ftpServer",
  data() {
    return {
      title: "",
      name: "",
      groupId: "",
      addKeyVisible: false,
      checkPasswordShow: false,
      updateData: {},
      loading: false,
      pageNo: 1,
      tableColumns: [
        {
          title: "编号",
          dataIndex: "id",
          ellipsis: true,
          width: 80,
        },
        {
          title: "名称",
          dataIndex: "name",
          ellipsis: true,
          width: 200,
        },
        {
          title: "类型",
          dataIndex: "type",
          ellipsis: true,
          width: 50,
        },
        {
          title: "主机",
          dataIndex: "host",
          ellipsis: true,
          width: 150,
        },
        {
          title: "端口",
          dataIndex: "port",
          ellipsis: true,
          width: 100,
        },
        {
          title: "用户名",
          dataIndex: "username",
          ellipsis: true,
          width: 200,
        },
        {
          title: "密码",
          dataIndex: "password",
          ellipsis: true,
          scopedSlots: { customRender: "password" },
          width: 130,
        },
        {
          title: "启用状态",
          ellipsis: true,
          dataIndex: "enabled",
          scopedSlots: { customRender: "enabled" },
          align: "center",
          width: 100,
        },
        {
          title: "备注",
          dataIndex: "remark",
          ellipsis: true,
          width: 100,
        },
        {
          title: "创建时间",
          dataIndex: "gmtCreated",
          ellipsis: true,
          width: 150,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 150,
        },
      ],
      tableDataSource: [],
      tablePagination: {},
      loadingTable: false,
      ftpTypeList: [
        {
          value: "ftp",
          label: "ftp",
        },
        {
          value: "sftp",
          label: "sftp",
        },
      ],
      expireAtList: [
        { id: 1, label: "永不过期", value: -1 },
        { id: 2, label: "1天", value: 1 },
        { id: 3, label: "2天", value: 2 },
        { id: 4, label: "3天", value: 3 },
        { id: 5, label: "5天", value: 5 },
        { id: 6, label: "7天", value: 7 },
        { id: 7, label: "10天", value: 10 },
        { id: 8, label: "15天", value: 15 },
      ],
    };
  },
  mounted() {
    this.query(1);
  },
  methods: {
    ftpTypeChange(value) {
      if (value == "ftp") {
        this.port = "21";
      } else {
        this.port = "22";
      }
    },
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query(1);
    },
    // 点击查询
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
        name: this.name,
      };
      this.loadingTable = true;
      this.$http
        .get("/ftp/ftpServerPage", data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            this.tableDataSource = list;
            this.tablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.changePageItem(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 翻页
    changePageItem(index) {
      this.query(index);
    },
    checkPassword(val) {
      this.modifyClick(val, true);
    },
    ftpServerEnable(val) {
      let data = {
        id: val.id,
        enabled: !val.enabled,
      };
      this.$http.json_post("/ftp/ftpServerEnable", data).then((res) => {
        if (res.result === 200) {
          if (val.enabled) {
            this.$message.success("禁用成功");
          } else {
            this.$message.success("启用成功");
          }
          this.query();
        }
      });
    },
    // 修改
    modifyClick(val, checkPasswordShow) {
      if (checkPasswordShow) {
        this.checkPasswordShow = true;
        this.title = "查看密码";
      } else {
        this.title = "修改";
        this.checkPasswordShow = false;
      }

      this.addKeyVisible = true;
      this.groupId = val.id;
      this.updateData = {
        name: val.name,
        type: val.type,
        initialPath: val.initialPath,
        host: val.host,
        port: val.port,
        username: val.username,
        password: val.password,
        remark: val.remark,
        enterLocalPassiveMode: val.enterLocalPassiveMode,
        expireAt: val.expireAt,
      };
    },
    // 新增
    add() {
      this.title = "新增";
      this.checkPasswordShow = false;
      this.addKeyVisible = true;
      this.updateData = {
        name: "",
        type: "ftp",
        initialPath: "/",
        host: "",
        port: "21",
        username: "",
        password: "",
        remark: "",
        enterLocalPassiveMode: false,
        expireAt: -1,
      };
    },
    // 确定新增
    add_submit() {
      let data = { ...this.updateData };
      if (
        !data.name ||
        !data.type ||
        !data.initialPath ||
        !data.host ||
        !data.port ||
        !data.username ||
        !data.password
      ) {
        this.$message.warning("请填写必填信息");
      }
      if (data.type == "sftp") {
        delete data.enterLocalPassiveMode;
      }
      data.expireAt = data.expireAt * 1;
      if (data.expireAt > 15) {
        data.expireAt = 15;
      }
      if (this.title === "新增") {
        this.$http.json_post("/ftp/addFtpServer", data).then((res) => {
          if (res.result === 200) {
            this.addKeyVisible = false;
            this.$message.success("添加成功");
            this.query();
          }
        });
      } else {
        data.id = this.groupId;

        this.$http.json_post("/ftp/editFtpServer", data).then((res) => {
          if (res.result === 200) {
            this.addKeyVisible = false;
            this.$message.success("修改成功");
            this.query();
          }
        });
      }
    },
    connectTest(val) {
      let data = {
        id: val.id,
      };
      this.$http.json_post("/ftp/connectTest", data).then((res) => {
        if (res.result === 200) {
          this.$message.success("连接成功");
        }
      });
    },
    // 点击删除
    deleteClick(val) {
      let data = {
        id: val.id,
      };
      this.$http.json_post("/ftp/deleteFtpServer", data).then((res) => {
        if (res.result === 200) {
          this.$message.success("删除成功");
          this.query();
        }
      });
    },
  },
};
</script>
