<template>
  <div class="subtaskList">
    <div class="searchCondition">
      <div class="searchConditionItem" v-if="!parentTaskId">
        <span>序号：</span>
        <a-input
          v-model.trim="id"
          placeholder="请输入"
          style="width: 90px"
          allowClear
          @change="allowClearChange"
          @pressEnter="query(1)"
        />
      </div>
      <div class="searchConditionItem">
        <span>任务名称：</span>
        <a-input
          v-model.trim="name"
          placeholder="请输入任务名称"
          allowClear
          @change="allowClearChange"
          @pressEnter="query(1)"
        />
      </div>
      <div
        class="searchConditionItem"
        v-if="!parentTaskId || (parentTaskId && parentType == 'API_INTERFACE')"
      >
        <span>状态：</span>
        <a-select
          v-model="statusSelect"
          allowClear
          placeholder="请选择"
          @change="query(1)"
        >
          <a-select-option
            v-for="item in statusList"
            :key="item.code"
            :value="item.code"
          >
            {{ item.desc }}
          </a-select-option>
        </a-select>
      </div>
      <div class="searchConditionItem" v-if="!parentTaskId">
        <span>提交人：</span>
        <a-select
          v-model="timedSubmitter"
          placeholder="请选择"
          showSearch
          :filterOption="false"
          allowClear
          @search="searchUserList"
          @blur="getUserList()"
          @change="query(1)"
          style="width: 100px"
        >
          <a-select-option
            v-for="item in submitterList"
            :key="item.id"
            :value="item.loginName"
          >
            {{ item.userName }}
          </a-select-option>
        </a-select>
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
      </div>
    </div>
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(subtaskListTableColumns)"
      :columns="subtaskListTableColumns"
      :dataSource="subtaskListTableData"
      :pagination="subtaskListTablePagination"
      size="small"
      :rowKey="(record) => record.id"
      :loading="subtaskListLoadingTable"
      :row-selection="rowSelection"
    >
      <span slot="status" slot-scope="text, record">
        <a-tag :color="record.statusColor">{{ record.statusLabel }}</a-tag>
        <a-tooltip
          v-if="record.status == 'SCHEDULED'"
          :title="'执行时间:' + record.executeDate"
          placement="top"
        >
          <a-icon type="exclamation-circle" style="color: #d2983c" />
        </a-tooltip>
      </span>
      <span slot="executeSecond" slot-scope="text, record">
        <span v-if="record.status == 'SCHEDULED'">-</span>
        <span v-else>
          {{ $common.timeConsuming(record.executeSecond) }}
        </span>
      </span>
      <span slot="action" slot-scope="text, record">
        <a-button type="link" @click="cook_pipeline(record)">{{
          record.status == "DRAFT" ? "编辑" : "查看"
        }}</a-button>
        <a-divider type="vertical" />
        <a-popconfirm
          placement="left"
          v-if="record.status == 'WAITING'"
          okText="确认"
          cancelText="取消"
          @confirm="up_pipeline(record)"
        >
          <template slot="title">是否启动{{ record.name }}任务?</template>
          <a href="javascript:;">启动任务</a>
        </a-popconfirm>
        <a-divider type="vertical" v-if="record.status == 'WAITING'" />
        <a-button
          type="link"
          @click="file_download(record)"
          :disabled="record.status !== 'SUCCESS' && record.expired !== true"
          >文件下载</a-button
        >
        <template
          v-if="
            record.status == 'RUNNING' || pipelineTaskType == 'SCHEDULED_TASK'
          "
        >
          <a-divider type="vertical" />
          <a-popconfirm
            placement="left"
            okText="确认"
            cancelText="取消"
            @confirm="stopTaskNow(record)"
            :disabled="record.status !== 'RUNNING'"
          >
            <template slot="title">是否中断执行?</template>
            <a-button
              type="link"
              size="small"
              :disabled="record.status !== 'RUNNING'"
              >中断执行</a-button
            >
          </a-popconfirm>
        </template>

        <template
          v-if="
            pipelineTaskType == 'API_TASK' && parentType !== 'API_TASK_TEMPLATE'
          "
        >
          <a-divider type="vertical" />
          <a-popconfirm
            placement="left"
            okText="确认"
            cancelText="取消"
            @confirm="deletePipelineTask(record)"
          >
            <template slot="title">确认是否删除?</template>
            <a href="javascript:;" style="color: #ff4d4f">删除</a>
          </a-popconfirm>
        </template>
      </span>
    </a-table>

    <div v-if="parentType == 'API_INTERFACE'" style="margin-top: 20px">
      <a-button
        @click="batchDelete()"
        type="danger"
        :disabled="this.subtaskListTableSelectKeys.length == 0"
        icon="delete"
        >批量删除</a-button
      >
      <a-button
        @click="batchStart()"
        type="primary"
        style="margin-left: 10px"
        :disabled="batchStartDisable"
        >批量启动</a-button
      >
    </div>

    <a-modal
      :maskClosable="false"
      v-model="fileListShow"
      width="1000px"
      :afterClose="fileListClosed"
      :footer="null"
      title="下载文件列表"
    >
      <a-tabs v-model="fileActive">
        <a-tab-pane tab="重要文件" key="first"></a-tab-pane>
        <a-tab-pane tab="临时文件" key="second"></a-tab-pane>
      </a-tabs>
      <a-table
        :rowClassName="$common.rowClassColor"
        bordered
        :components="$common.getTitle(fileListTableColumns)"
        :columns="fileListTableColumns"
        :dataSource="fileListTableData"
        :pagination="false"
        size="small"
        :rowKey="(record) => record.id"
        :loading="fileListLoadingTable"
      >
        <span slot="lineCountLabel" slot-scope="text, record">
          <a-tooltip placement="left">
            <div slot="title">
              {{ record.lineCount }}
            </div>
            <span> {{ text }} </span>
          </a-tooltip>
        </span>
        <span slot="action" slot-scope="text, record">
          <a-button
            type="link"
            size="small"
            @click="downloadClick(record)"
            :disabled="record.expired"
            >下载</a-button
          >
          <a-divider type="vertical" />
          <a-button type="link" size="small" @click="shareClick(record)"
            >分享</a-button
          >
          <a-divider type="vertical" />
          <a-button
            type="link"
            size="small"
            @click="checkFileContent(record)"
            :disabled="
              record.fileName.split('.')[
                record.fileName.split('.').length - 1
              ] == 'zip'
            "
            >查看</a-button
          >
          <a-divider type="vertical" />
          <a-button type="link" size="small" @click="copyPath(record)"
            >复制路径</a-button
          >
        </span>
      </a-table>
    </a-modal>

    <a-modal
      :maskClosable="false"
      width="30%"
      title="分享设置"
      v-model="innerVisible"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 15 }">
        <a-form-item label="密码">
          <a-switch
            v-model="passwordSwitch"
            active-color="#13ce66"
            inactive-color="#ff4949"
            @change="password == ''"
          />
          <template v-if="passwordSwitch">
            <a-input
              v-model="password"
              placeholder="请输入8位以上密码"
              style="width: 250px; margin: 0 5px"
            />
            <a-button @click="random" type="link">随机</a-button>
          </template>
        </a-form-item>
        <a-form-item label="过期时间">
          <a-select v-model="expireSeconds" placeholder="请选择过期时间">
            <a-select-option
              v-for="item in expireSecondList"
              :key="item.id"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="文件空间">
          <a-select v-model="fileSpaceId" placeholder="请选择">
            <a-select-option
              v-for="item in fileSpaceList"
              :key="item.id"
              :value="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="备注">
          <a-textarea v-model="remark" placeholder="请输入备注" :rows="4" />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button @click="innerVisible = false">取 消</a-button>
          <a-button type="primary" @click="shareConfirm">确 定</a-button>
        </div>
      </template>
    </a-modal>

    <a-modal
      width="30%"
      title="复制链接"
      v-model="copyVisible"
      :footer="false"
      :maskClosable="false"
    >
      <a-textarea v-model="inviteCode" :rows="2" />
      <a-button
        type="primary"
        class="copy-code-button"
        v-copy="inviteCode"
        style="margin-top: 10px"
        >一键复制</a-button
      >
    </a-modal>

    <a-modal
      :maskClosable="false"
      width="700px"
      title="查看文件"
      v-model="checkFileContentShow"
      :footer="null"
    >
      <a-table
        v-if="fileType == 'xls' || fileType == 'xlsx'"
        :rowClassName="$common.rowClassColor"
        bordered
        :components="$common.getTitle(excelFileColumns)"
        :columns="excelFileColumns"
        :dataSource="excelFileList"
        :pagination="false"
        size="small"
        :rowKey="(record) => record.id"
      />

      <a-textarea
        v-else
        v-model="fileContent"
        :rows="20"
        style="margin-bottom: 10px"
      />
      <h4
        style="margin: 0 0 10px 0"
        v-if="
          fileContent.split('\n').length == 500 || excelFileList.length == 500
        "
      >
        最多只能显示五百行
      </h4>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: "subtaskList",
  props: ["parentType", "pipelineTaskType", "parentTaskId", "querySubTask"],
  data() {
    return {
      id: "",
      name: "",
      statusSelect: undefined,
      statusList: [],
      timedSubmitter: undefined,
      submitterList: [],
      subtaskCurrentPage: 1,
      subtaskListTableData: [],
      subtaskListLoadingTable: false,
      rowSelection: null,
      subtaskListTableSelectKeys: [],
      subtaskListTableSelectKeysData: [],
      subtaskListTableColumns: [
        {
          title: "序号",
          ellipsis: true,
          dataIndex: "id",
          align: "center",
          width: 100,
        },
        {
          title: "账号",
          ellipsis: true,
          dataIndex: "userLoginName",
          width: 150,
        },
        {
          title: "任务名称",
          ellipsis: true,
          dataIndex: "name",
          width: 350,
        },
        {
          title: "状态",
          ellipsis: true,
          dataIndex: "status",
          scopedSlots: { customRender: "status" },
          align: "center",
          width: 100,
        },
        {
          title: "耗时",
          ellipsis: true,
          dataIndex: "executeSecond",
          scopedSlots: { customRender: "executeSecond" },
          align: "center",
          width: 80,
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
          align: "center",
          width: 170,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 230,
        },
      ],
      subtaskListTablePagination: {},
      fileListTableData: [],
      fileListLoadingTable: false,
      fileListTableColumns: [
        {
          title: "编号",
          ellipsis: true,
          dataIndex: "id",
          width: 90,
          align: "center",
        },
        {
          title: "文件名称",
          ellipsis: true,
          dataIndex: "fileName",
          width: 180,
        },
        {
          title: "文件大小",
          ellipsis: true,
          dataIndex: "fileSize",
          width: 100,
          align: "center",
        },
        {
          title: "行数",
          ellipsis: true,
          dataIndex: "lineCountLabel",
          scopedSlots: { customRender: "lineCountLabel" },
          width: 80,
          align: "center",
        },
        {
          title: "标签",
          ellipsis: true,
          dataIndex: "label",
          width: 80,
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
          align: "center",
          width: 170,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 220,
        },
      ],
      fileListShow: false,
      innerVisible: false,
      password: "",
      expireSeconds: "",
      expireSecondList: [
        { id: 1, label: "永不过期", value: -1 },
        { id: 2, label: "1天", value: 86400 },
        { id: 3, label: "2天", value: 172800 },
        { id: 4, label: "3天", value: 259200 },
        { id: 5, label: "7天", value: 604800 },
        { id: 6, label: "15天", value: 1296000 },
        { id: 7, label: "一个月", value: 2592000 },
        { id: 8, label: "31天", value: 2592000 },
        { id: 9, label: "45天", value: 3888000 },
        { id: 10, label: "6个月", value: 15552000 },
        { id: 11, label: "1年", value: 31536000 },
      ],
      remark: "",
      passwordSwitch: false,
      pipelineLogId: "",
      fileActive: "first",
      copyVisible: false,
      inviteCode: "",
      fileType: "txt",
      checkFileContentShow: false,
      fileContent: "",
      excelFileList: [],
      excelFileColumns: [],
      shareTimer: null,
      syncLoading: null,
      fileSpaceId: -1,
      fileSpaceList: [],
      searchTimer: null,
      queryRestoreStatusTimer: null,
    };
  },
  watch: {
    fileActive: {
      handler(newVal, oldVal) {
        switch (newVal) {
          case "first":
            this.getFileListTableData(this.pipelineLogId, true);
            break;
          case "second":
            this.getFileListTableData(this.pipelineLogId, false);
            break;
        }
      },
    },
  },
  computed: {
    batchStartDisable() {
      let arr = this.subtaskListTableSelectKeysData.filter((item) => {
        return item.status == "WAITING";
      });
      if (arr.length > 0) {
        return false;
      }
      return true;
    },
  },
  created() {
    if (this.parentType == "API_INTERFACE") {
      this.rowSelection = {
        onChange: this.onSelectChange,
        selectedRowKeys: this.subtaskListTableSelectKeys,
      };
    }
    this.getStatus();
    if (this.pipelineTaskType !== "API_TASK") {
      this.subtaskListTableColumns.splice(1, 1);
    }
    this.query();
  },
  methods: {
    searchUserList(name) {
      if (this.searchTimer) {
        clearTimeout(this.searchTimer);
        this.searchTimer = null;
      }
      this.searchTimer = setTimeout(() => {
        this.getUserList(name);
      }, 300);
    },
    getUserList(name) {
      this.$http.get("/user/list", { name }).then((res) => {
        if (res.result === 200) {
          this.submitterList = res.data;
        }
      });
    },
    getStatus() {
      this.$http.get("/sysPipeline/statusList").then((res) => {
        if (res.result === 200) {
          this.statusList = res.data;
        }
      });
    },
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query(1);
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.subtaskListTableSelectKeys.splice(0);
      this.subtaskListTableSelectKeys.push(...selectedRowKeys);
      this.subtaskListTableSelectKeysData.splice(0);
      this.subtaskListTableSelectKeysData.push(...selectedRows);
    },
    // 批量删除
    batchDelete() {
      this.$confirm({
        title: "提示",
        content: (h) => (
          <div style="color:red;">
            删除{this.subtaskListTableSelectKeys.length}个任务, 是否继续?
          </div>
        ),
        okText: "确定",
        cancelText: "取消",
        onOk: () => {
          if (this.subtaskListTableSelectKeys.length > 0) {
            var idList = this.subtaskListTableSelectKeys;
            let data = {
              idList,
            };
            this.$http
              .json_post("/pipelineTask/batchDelete", data)
              .then((res) => {
                if (res.result === 200) {
                  this.$message.success("批量删除成功");
                } else {
                  this.$message.warning("批量删除失败");
                }
                this.query();
                this.subtaskListTableSelectKeys.splice(0);
                this.subtaskListTableSelectKeysData.splice(0);
              });
          }
        },
        onCancel: () => {},
      });
    },
    batchStart() {
      let ids = [];
      this.subtaskListTableSelectKeysData.forEach((item) => {
        if (item.status == "WAITING" && item.groupId !== 0) {
          ids.push(item.id);
        }
      });
      this.$confirm({
        title: "提示",
        content: (h) => (
          <div style="color:red;">批量启动{ids.length}个任务, 是否继续?</div>
        ),
        okText: "确定",
        cancelText: "取消",
        onOk: () => {
          this.$axios
            .all(
              ids.map((id) => {
                let data = { id };
                return this.$http.json_post("/pipelineTask/start", data);
              })
            )
            .then((resArr) => {
              this.$message.success("启动成功");
              this.subtaskListTableSelectKeys.splice(0);
              this.subtaskListTableSelectKeysData.splice(0);
              this.query();
            });
        },
        onCancel: () => {},
      });
    },
    query(index) {
      this.subtaskListLoadingTable = true;
      if (index) {
        this.subtaskCurrentPage = index;
      }
      let data = {
        pageNo: this.subtaskCurrentPage,
        pageSize: 10,
        name: this.name,
        id: this.id,
        status: this.statusSelect,
        querySubTask: this.querySubTask,
        pipelineTaskType: this.pipelineTaskType,
        parentTaskId: this.parentTaskId,
        submitter: this.submitter,
      };
      this.$http.get("/pipelineTask/list", data).then((res) => {
        if (res.result === 200) {
          let list = res.data.records;
          let ids = [];
          list.forEach((item) => {
            item.gmtCreated = this.$common.transformTime(item.gmtCreated);
            if (item.status === "RUNNING" || item.status === "WAITING") {
              ids.push(item.id);
            }
            switch (item.status) {
              case "QUEUEING":
                item.statusColor = "orange";
                item.statusLabel = "排队中";
                break;
              case "RUNNING":
                item.statusColor = "orange";
                item.statusLabel = "运行中";
                break;
              case "SUCCESS":
                item.statusColor = "green";
                item.statusLabel = "执行成功";
                break;
              case "ERROR":
                item.statusColor = "red";
                item.statusLabel = "执行失败";
                break;
              case "WAITING":
                item.statusColor = "orange";
                item.statusLabel = "等待执行";
                break;
              case "CANCELED":
                item.statusColor = "orange";
                item.statusLabel = "已取消";
                break;
              case "WAITING_AUDIT":
                item.statusColor = "orange";
                item.statusLabel = "等待审核";
                break;
              case "AUDIT_FAILURE":
                item.statusColor = "red";
                item.statusLabel = "审核失败";
                break;
              case "PART_READY":
                item.statusColor = "orange";
                item.statusLabel = "部分就绪";
                break;
              case "SCHEDULED":
                item.statusColor = "orange";
                item.statusLabel = "已定时";
                break;
              case "DRAFT":
                item.statusColor = "orange";
                item.statusLabel = "草稿";
                break;
            }
          });
          this.subtaskListTableData = list;
          this.subtaskListTablePagination = {
            showQuickJumper: true,
            showTotal: () => `共${res.data.total}条`,
            pageSize: data.pageSize,
            current: data.pageNo,
            total: res.data.total,
            onChange: (current) => this.query(current),
          };
          this.subtaskListLoadingTable = false;
        }
      });
    },
    cook_pipeline(val) {
      this.$router.push({
        path: "/panel",
        query: {
          id: val.id,
        },
      });
    },
    // 启动任务
    up_pipeline(val) {
      let data = {
        id: val.id,
      };
      this.$http.json_post("/pipelineTask/start", data).then((res) => {
        if (res.result === 200) {
          this.$message.success("启动成功");
          this.query(1);
        }
      });
    },
    // 中断执行
    stopTaskNow(val) {
      let data = {
        id: val.id,
      };
      this.$http.json_post("/pipelineTask/interruptTask", data).then((res) => {
        if (res.result === 200) {
          this.$message.success("操作成功");
          this.query(1);
        }
      });
    },
    // 点击文件下载
    file_download(val) {
      this.fileListShow = true;
      this.pipelineLogId = val.id;
      this.getSpaceList();
      this.getFileListTableData(val.id, true);
    },
    getSpaceList() {
      this.$http.get("/job/getSpaceList").then((res) => {
        if (res.result === 200) {
          let list = res.data;
          this.fileSpaceList = list;
        }
      });
    },
    getFileListTableData(pipelineLogId, importantFile) {
      this.fileListLoadingTable = true;
      let data = {
        pipelineLogId,
        importantFile,
      };
      this.$http.get("/pipelineFile/fileList", data).then((res) => {
        if (res.result === 200) {
          this.fileListTableData = res.data;
          this.fileListTableData.map((item) => {
            if (item.lineCount == -1) {
              this.$set(item, "lineCount", "未知");
              this.$set(item, "lineCountLabel", "未知");
            } else {
              this.$set(
                item,
                "lineCountLabel",
                this.$common.formatTotalCount(item.lineCount)
              );
            }
          });
          this.fileListLoadingTable = false;
        }
      });
    }, // 密码随机
    random() {
      this.password = this.$common.random();
    },
    // 分享确定
    shareConfirm() {
      this.syncLoading = this.$syncLoading({
        message: "分享链接生成中...",
      });
      this.getFileShareLink();
    },
    getFileShareLink() {
      let data = {
        id: this.jobId,
        fileId: this.fileId,
        password: this.password,
        expireSeconds: this.expireSeconds,
        fileSpaceId: this.fileSpaceId,
        remark: this.remark,
      };
      this.innerVisible = false;
      this.$http
        .get("/pipelineTask/getFileShareLink", data)
        .then((res) => {
          if (res.result === 200) {
            if (res.data.url) {
              this.copyVisible = true;
              this.inviteCode = res.data.url;
              this.syncLoading.close();
              this.$message.success("分享成功");
              clearInterval(this.shareTimer);
              this.shareTimer = null;
            } else if (!this.shareTimer) {
              this.shareTimer = setInterval(() => {
                this.getFileShareLink();
              }, 2000);
            }
          }
        })
        .catch(() => {
          this.syncLoading.close();
        });
    },
    checkFileContent(val) {
      this.fileContent = "";
      this.excelFileList.splice(0);
      this.excelFileColumns.splice(0);
      this.fileType = val.fileName.split(".")[1];
      this.syncLoading = this.$syncLoading({
        message: "获取文件内容中...",
      });
      this.$http
        .get("/pipelineFile/checkFileContent", {
          id: val.id,
          type: "fileId",
        })
        .then((res) => {
          if (res.result == 200) {
            this.syncLoading.close();
            if (res.data.state == "ARCHIVED") {
              this.$confirm({
                title: "文件已归档，查看需要进行解冻",
                content: (h) => <div style="color:red;">确认解冻吗</div>,
                okText: "确定",
                cancelText: "取消",
                onOk: () => {
                  this.restore(id);
                },
                onCancel: () => {},
              });
            } else if (res.data.state == "SUCCESS") {
              if (this.fileType == "xlsx" || this.fileType == "xls") {
                res.data.data.forEach((item, index) => {
                  let obj = {
                    id: index + 1,
                  };
                  item.forEach((value, key) => {
                    obj["column" + (key + 1)] = value;
                  });
                  this.excelFileList.push(obj);
                });

                res.data.data[0].forEach((value, index) => {
                  this.excelFileColumns.push({
                    title: "列" + (index + 1),
                    ellipsis: true,
                    dataIndex: "column" + (index + 1),
                    width: 100,
                  });
                });
              } else {
                this.fileContent = res.data.data[0].join("\n");
              }
              this.checkFileContentShow = true;
            }
          }
        })
        .catch(() => {
          this.syncLoading.close();
        });
    },
    restore(id) {
      this.syncLoading = this.$syncLoading({
        message: "解冻中...",
      });
      this.$http
        .post("/pipelineFile/restore", {
          id,
          type: "fileId",
        })
        .then((res) => {
          if (res.result == 200) {
            this.queryRestoreStatus(id);
          }
        })
        .catch(() => {
          this.syncLoading.close();
        });
    },
    queryRestoreStatus(id) {
      this.$http
        .get("/pipelineFile/queryRestoreStatus", {
          id,
          type: "fileId",
        })
        .then((res) => {
          if (res.result == 200) {
            if (res.data !== "RUNNING") {
              clearTimeout(this.queryRestoreStatusTimer);
              this.queryRestoreStatusTimer = null;
              this.syncLoading.close();
              if (res.data == "DONE") {
                this.$message.success("解冻成功");
              } else {
                this.$message.error("解冻失败");
              }
            } else {
              this.queryRestoreStatusTimer = setTimeout(() => {
                this.queryRestoreStatus(id);
              }, 1000);
            }
          }
        })
        .catch(() => {
          this.syncLoading.close();
        });
    },
    // 点击分享
    shareClick(val) {
      this.fileId = val.id;
      this.shareUrl = val.link;
      this.innerVisible = true;
      this.jobId = val.pipelineTaskId;
      this.passwordSwitch = false;
      this.password = "";
      this.remark = "";
      this.expireSeconds = 2592000;
      this.fileSpaceId = -1;
    },
    copyPath(val) {
      let data = {
        id: val.id,
      };
      this.$http.get("/pipelineFile/copyFilePath", data).then((res) => {
        if (res.result === 200) {
          this.$copyText(res.data).then(
            (e) => {
              this.$message.success("复制成功");
            },
            (e) => {
              this.$message.error("复制失败");
            }
          );
        }
      });
    },
    // 关闭下载文件
    fileListClosed() {
      this.fileListTableData = [];
      this.fileActive = "first";
    },
    // 点击下载
    downloadClick(val) {
      let path = location.protocol + process.env.VUE_APP_BASE_API;
      let href = path + "/pipelineFile/download?id=" + val.id;
      window.location.href = href;
    },
    deletePipelineTask(val) {
      let data = {
        id: val.id,
      };
      this.$http.json_post("/pipelineTask/delete", data).then((res) => {
        if (res.result === 200) {
          this.$message.success("删除成功");
          this.query();
        }
      });
    },
  },
};
</script>
