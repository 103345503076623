<template>
  <div class="fileList">
    <a-tabs v-model="active">
      <a-tab-pane tab="文件列表" key="1">
        <div v-if="active == '1'">
          <div class="searchCondition">
            <div class="searchConditionItem">
              <span>文件名称：</span>
              <a-input
                v-model.trim="name"
                placeholder="请输入文件名称"
                allowClear
                @change="allowClearFileQueryChange"
                @pressEnter="fileQuery(1)"
              />
            </div>
            <div class="searchButton">
              <a-button type="primary" @click="fileQuery(1)" icon="search"
                >查询</a-button
              >
              <a-button type="primary" @click="add()" icon="plus"
                >新增文件</a-button
              >
              <a-button type="primary" @click="create()" icon="plus"
                >创建空文件</a-button
              >
            </div>
          </div>

          <a-table
            :rowClassName="$common.rowClassColor"
            bordered
            :components="$common.getTitle(tableColumns)"
            :columns="tableColumns"
            :dataSource="tableData"
            :pagination="tablePagination"
            size="small"
            :rowKey="(record) => record.id"
            :loading="loadingTable"
            :row-selection="{
              onChange: onSelectChange,
              selectedRowKeys: fileIdList,
            }"
          >
            <span slot="name" slot-scope="text, record" class="slotCell">
              <a-tooltip placement="left">
                <span slot="title"> 下载 </span>
                <a-button type="link" @click="downloadLocalFile(record)">
                  {{ record.name }}
                </a-button>
              </a-tooltip>
              <a-button type="link" class="copy-class" v-copy="record.name">
                复制
              </a-button>
            </span>
            <span slot="expireAt" slot-scope="text, record">
              <div
                style="
                  width: 100%;
                  display: flex;
                  flex-wrap: nowrap;
                  align-items: center;
                "
              >
                <div style="width: calc(100% - 32px); text-align: center">
                  {{ record.expirable ? text : "-" }}
                </div>
                <a-button
                  type="link"
                  icon="edit"
                  @click="modifyExpireAt(record)"
                />
              </div>
            </span>
            <span slot="action" slot-scope="text, record">
              <a-button type="link" @click="updateFile(record)">修改</a-button>
              <a-divider type="vertical" />
              <a-button type="link" v-copy="record.path">复制路径</a-button>
              <a-divider type="vertical" />
              <a-dropdown>
                <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
                  其它 <a-icon type="down" />
                </a>
                <a-menu slot="overlay">
                  <a-menu-item>
                    <a-button
                      type="link"
                      size="small"
                      @click="checkFileContent(record)"
                      >预览</a-button
                    >
                  </a-menu-item>
                  <a-menu-item>
                    <a-button type="link" size="small" @click="refresh(record)"
                      >刷新</a-button
                    >
                  </a-menu-item>
                  <a-menu-item>
                    <a-popconfirm
                      placement="left"
                      okText="确认"
                      cancelText="取消"
                      @confirm="empty(record)"
                    >
                      <template slot="title">
                        是否清空【{{ record.name }}】文件{{
                          record.count
                        }}条数据
                      </template>
                      <a-button type="link" size="small">清空</a-button>
                    </a-popconfirm>
                  </a-menu-item>
                  <a-menu-item>
                    <a-button
                      type="link"
                      size="small"
                      @click="uploadOss(record)"
                    >
                      上传到oss
                    </a-button>
                  </a-menu-item>
                  <a-menu-item>
                    <a-button
                      type="link"
                      size="small"
                      @click="downloadLocal(record)"
                    >
                      下载到本地
                    </a-button>
                  </a-menu-item>
                  <a-menu-item>
                    <a-popconfirm
                      placement="left"
                      okText="确认"
                      cancelText="取消"
                      @confirm="deleteFile(record)"
                    >
                      <template slot="title">
                        是否删除【{{ record.name }}】文件
                      </template>
                      <a href="javascript:;" style="color: #ff4d4f">删除</a>
                    </a-popconfirm>
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </span>
          </a-table>
          <a-dropdown :disabled="fileIdList.length == 0">
            <a-button type="primary" style="margin-bottom: 10px">
              批量操作 <a-icon type="down" />
            </a-button>
            <a-menu slot="overlay">
              <a-menu-item>
                <a href="javascript:;" @click="batchRefresh">批量刷新</a>
              </a-menu-item>
              <a-menu-item>
                <a href="javascript:;" @click="batchDelete">批量删除</a>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </div>
      </a-tab-pane>
      <a-tab-pane tab="文件集列表" key="2">
        <div v-if="active == '2'">
          <div class="searchCondition">
            <div class="searchConditionItem">
              <span>文件集名称：</span>
              <a-input
                v-model.trim="fileCollectName"
                placeholder="请输入文件集名称"
                allowClear
                @change="allowClearFileCollectQueryChange"
                @pressEnter="fileCollectQuery(1)"
              />
            </div>
            <div class="searchButton">
              <a-button
                type="primary"
                @click="fileCollectQuery(1)"
                icon="search"
              >
                查询
              </a-button>
              <a-button type="primary" @click="fileCollectAdd()" icon="plus"
                >新增文件集</a-button
              >
            </div>
          </div>
          <a-table
            :rowClassName="$common.rowClassColor"
            bordered
            :components="$common.getTitle(fileCollectTableColumns)"
            :columns="fileCollectTableColumns"
            :dataSource="fileCollectTableData"
            :pagination="fileCollectTablePagination"
            size="small"
            :rowKey="(record) => record.id"
            :loading="fileCollectLoadingTable"
          >
            <span slot="expireDays" slot-scope="text, record">
              {{ record.expireDays == 0 ? "永不过期" : record.expireDays }}
            </span>
            <span slot="action" slot-scope="text, record">
              <a-button
                type="link"
                @click="fileQuery(1, 10, record.id, record.name)"
                >详情</a-button
              >
              <a-divider type="vertical" />
              <a-button type="link" @click="fileCollectEdit(record)"
                >修改</a-button
              >
              <a-divider type="vertical" />
              <a-popconfirm
                placement="left"
                okText="确认"
                cancelText="取消"
                @confirm="fileCollectDelete(record)"
              >
                <template slot="title">确认是否删除?</template>
                <a href="javascript:;" style="color: #ff4d4f">删除</a>
              </a-popconfirm>
            </span>
          </a-table>
        </div>
      </a-tab-pane>
    </a-tabs>

    <a-modal
      :maskClosable="false"
      title="修改"
      v-if="cronTaskShow"
      v-model="cronTaskShow"
      width="450px"
    >
      <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="文件名称">
          <a-input v-model="nameUp" placeholder="请输入文件名称" />
        </a-form-item>
        <a-form-item label="备注">
          <a-textarea v-model="remarkUp" placeholder="请输入备注" />
        </a-form-item>
        <a-form-item label="过期时间">
          <a-switch v-model="expirable" @change="expirableChange" />
          <a-date-picker
            v-if="expirable"
            v-model="expireAt"
            placeholder="选择时间"
            value-format="YYYY-MM-DD HH:mm:ss"
            format="YYYY-MM-DD HH:mm:ss"
            showTime
            style="margin-left: 20px"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button type="primary" @click="cronTask_submit">确定</a-button>
          <a-button @click="cronTaskShow = false">取消</a-button>
        </div>
      </template>
    </a-modal>

    <!-- 新增 -->
    <a-modal
      :maskClosable="false"
      title="新增"
      v-model="dialogVisible"
      width="600px"
      :afterClose="closed"
    >
      <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="上传文件:" required>
          <a-upload-dragger
            ref="upload"
            :fileList="fileList"
            :beforeUpload="beforeUpload"
            :remove="handleRemove"
          >
            <p class="ant-upload-drag-icon">
              <a-icon type="inbox" />
            </p>
            <p class="ant-upload-text">单击或拖动文件到此区域进行上传</p>
          </a-upload-dragger>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button @click="dialogVisible = false">取 消</a-button>
          <a-button type="primary" @click="add_submit">确 定</a-button>
        </div>
      </template>
    </a-modal>

    <!-- 创建空文件 -->
    <a-modal
      :maskClosable="false"
      title="创建空文件"
      v-model="dialogVisible2"
      width="600px"
    >
      <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="文件名:" required>
          <a-input
            v-model="fileNameAdd"
            placeholder="请输入文件名称"
            allowClear
            style="width: 300px"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button @click="dialogVisible2 = false">取 消</a-button>
          <a-button type="primary" @click="create_submit">确 定</a-button>
        </div>
      </template>
    </a-modal>

    <a-modal
      :maskClosable="false"
      :title="fileCollectTitle"
      v-model="fileCollectVisible"
      width="500px"
    >
      <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="文件集名称">
          <a-input
            v-model="fileCollectNameAdd"
            placeholder="请输入文件集名称"
            style="width: 240px"
          />
        </a-form-item>
        <a-form-item label="文件有效期">
          <a-input v-model="expireDays" addon-after="天" style="width: 120px" />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button type="primary" @click="updateFileCollect_submit"
            >确定</a-button
          >
          <a-button @click="fileCollectVisible = false">取消</a-button>
        </div>
      </template>
    </a-modal>

    <a-modal
      :maskClosable="false"
      :title="detailTitle"
      v-model="fileCollectDetailVisible"
      width="1400px"
      :footer="null"
      :afterClose="addFileListClose"
    >
      <div style="overflow: auto">
        <div class="searchCondition">
          <div class="searchConditionItem">
            <span>文件名称：</span>
            <a-input
              v-model.trim="name"
              placeholder="请输入文件名称"
              allowClear
              @change="allowClearFileQueryChange($event, collectId)"
              @pressEnter="fileQuery(1, 10, collectId)"
            />
          </div>
          <div class="searchButton">
            <a-button
              type="primary"
              @click="fileQuery(1, 10, collectId)"
              icon="search"
              >查询</a-button
            >
            <a-button type="primary" @click="addFileList()" icon="plus"
              >新增文件</a-button
            >
            <a-button type="primary" @click="batchModifyExpireAt()" icon="plus"
              >批量修改过期时间</a-button
            >
          </div>
        </div>

        <a-table
          :rowClassName="$common.rowClassColor"
          bordered
          :components="$common.getTitle(fileCollectDetailTableColumns)"
          :columns="fileCollectDetailTableColumns"
          :dataSource="tableData"
          :pagination="tablePagination"
          size="small"
          :rowKey="(record) => record.id"
          :loading="loadingTable"
        >
          <span slot="name" slot-scope="text, record">
            <a-button type="link" @click="downloadLocalFile(record)">{{
              record.name
            }}</a-button>
          </span>
          <span slot="expireAt" slot-scope="text, record">
            {{ record.expirable ? text : "-" }}
          </span>

          <span slot="actionTitle" slot-scope="text, record">
            <a-tooltip placement="left">
              <span slot="title"> 删除仅将文件从文件集移除，不会物理删除 </span>
              操作
            </a-tooltip>
          </span>
          <span slot="action" slot-scope="text, record">
            <a-button type="link" @click="updateFile(record)">修改</a-button>
            <a-divider type="vertical" />
            <a-button
              type="link"
              @click="removeFromCollect(record)"
              style="color: #ff4d4f"
              >删除</a-button
            >
          </span>
        </a-table>
      </div>
    </a-modal>

    <a-modal
      title="批量修改过期时间"
      v-model="batchModifyExpireAtShow"
      :maskClosable="false"
      width="500px"
    >
      <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="选择文件:">
          <a-select
            v-model="previewFileId"
            placeholder="请选择文件"
            showSearch
            optionFilterProp="children"
          >
            <a-select-option
              v-for="(item, index) in batchModifyExpireAtFileList"
              :key="index"
              :value="item.id"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button
            type="primary"
            :disabled="!previewFileId"
            @click="previewBatchModifyExpireAt"
            >预览</a-button
          >
        </div>
      </template>
    </a-modal>

    <a-modal
      title="预览过期时间列表"
      v-model="previewBatchModifyExpireAtShow"
      :maskClosable="false"
      width="800px"
    >
      <a-table
        :rowClassName="$common.rowClassColor"
        bordered
        :components="$common.getTitle(previewBatchModifyExpireAtTableColumns)"
        :columns="previewBatchModifyExpireAtTableColumns"
        :dataSource="previewBatchModifyExpireAtTableData"
        :pagination="false"
        size="small"
        :rowKey="(record) => record.id"
        :scroll="{
          x: false,
          y: 500,
        }"
      >
        <span slot="expireAt" slot-scope="text, record">
          <div
            style="
              width: 100%;
              display: flex;
              flex-wrap: nowrap;
              align-items: center;
            "
          >
            <div style="width: calc(100% - 32px); text-align: center">
              {{ record.expirable ? text : "-" }}
            </div>
            <a-button type="link" icon="edit" @click="modifyExpireAt(record)" />
          </div>
        </span>
      </a-table>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button type="primary" @click="batchModifyExpireAtSubmit"
            >确定</a-button
          >
          <a-button @click="previewBatchModifyExpireAtShow = false"
            >取消</a-button
          >
        </div>
      </template>
    </a-modal>

    <a-modal
      :maskClosable="false"
      title="添加文件"
      v-model="addFileListShow"
      width="1400px"
      :afterClose="addFileListClose"
    >
      <div class="searchCondition">
        <div class="searchConditionItem">
          <span>文件名称：</span>
          <a-input
            v-model.trim="name"
            placeholder="请输入文件名称"
            allowClear
            @change="allowClearFileQueryChange"
            @pressEnter="fileQuery(1)"
          />
        </div>
        <div class="searchButton">
          <a-button type="primary" @click="fileQuery(1)" icon="search"
            >查询</a-button
          >
        </div>
      </div>
      <div style="overflow: auto">
        <a-table
          :rowClassName="$common.rowClassColor"
          bordered
          :components="$common.getTitle(addTableColumns)"
          :columns="addTableColumns"
          :dataSource="tableData"
          :pagination="tablePagination"
          size="small"
          :row-selection="{
            onChange: onSelectChange,
            selectedRowKeys: fileIdList,
          }"
          :rowKey="(record) => record.id"
          :loading="loadingTable"
        >
          <span slot="name" slot-scope="text, record">
            <a-button type="link" @click="downloadLocalFile(record)">{{
              record.name
            }}</a-button>
          </span>
        </a-table>
      </div>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button @click="addFileListShow = false">取 消</a-button>
          <a-button type="primary" @click="addFileList_submit">确 定</a-button>
        </div>
      </template>
    </a-modal>

    <a-modal
      title="修改过期时间"
      v-model="modifyExpireAtShow"
      :maskClosable="false"
      width="500px"
    >
      <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="过期时间">
          <a-switch v-model="expirable" @change="expirableChange" />
          <a-date-picker
            v-if="expirable"
            v-model="expireAt"
            placeholder="选择时间"
            value-format="YYYY-MM-DD HH:mm:ss"
            format="YYYY-MM-DD HH:mm:ss"
            showTime
            style="margin-left: 20px"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button type="primary" @click="modifyExpireAtSubmit">确定</a-button>
          <a-button @click="modifyExpireAtShow = false">取消</a-button>
        </div>
      </template>
    </a-modal>

    <a-modal
      :maskClosable="false"
      width="700px"
      title="预览文件"
      v-model="checkFileContentShow"
      :footer="null"
    >
      <a-table
        v-if="fileType == 'xls' || fileType == 'xlsx'"
        :rowClassName="$common.rowClassColor"
        bordered
        :components="$common.getTitle(excelFileColumns)"
        :columns="excelFileColumns"
        :dataSource="excelFileList"
        :pagination="false"
        size="small"
        :rowKey="(record) => record.id"
      />

      <a-textarea
        v-else
        v-model="fileContent"
        :rows="20"
        style="margin-bottom: 10px"
      />
      <h4
        style="margin: 0 0 10px 0"
        v-if="
          fileContent.split('\n').length == 500 || excelFileList.length == 500
        "
      >
        最多只能显示五百行
      </h4>
    </a-modal>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "fileList",
  data() {
    return {
      name: "",
      nameUp: "",
      remarkUp: "",
      cronTaskShow: false,
      tableColumns: [
        {
          title: "编号",
          ellipsis: true,
          dataIndex: "id",
          width: 100,
          align: "center",
        },
        {
          title: "文件名称",
          ellipsis: true,
          dataIndex: "name",
          scopedSlots: { customRender: "name" },
          width: 300,
        },
        {
          title: "文件大小",
          ellipsis: true,
          dataIndex: "fileSize",
          align: "center",
          width: 130,
        },
        {
          title: "行数",
          ellipsis: true,
          dataIndex: "count",
          align: "center",
          width: 130,
        },
        {
          title: "备注",
          ellipsis: true,
          dataIndex: "remark",
          width: 200,
        },
        {
          title: "过期时间",
          ellipsis: true,
          dataIndex: "expireAt",
          scopedSlots: { customRender: "expireAt" },
          align: "center",
          width: 210,
        },
        {
          title: "上次修改时间",
          ellipsis: true,
          dataIndex: "gmtModified",
          align: "center",
          width: 170,
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
          align: "center",
          width: 170,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 240,
        },
      ],
      addTableColumns: [
        {
          title: "编号",
          ellipsis: true,
          dataIndex: "id",
          width: 100,
          align: "center",
        },
        {
          title: "文件名称",
          ellipsis: true,
          dataIndex: "name",
          scopedSlots: { customRender: "name" },
          width: 300,
        },
        {
          title: "文件大小",
          ellipsis: true,
          dataIndex: "fileSize",
          align: "center",
          width: 130,
        },
        {
          title: "行数",
          ellipsis: true,
          dataIndex: "count",
          align: "center",
          width: 130,
        },
        {
          title: "备注",
          ellipsis: true,
          dataIndex: "remark",
          width: 200,
        },
        {
          title: "过期时间",
          ellipsis: true,
          dataIndex: "expireAt",
          scopedSlots: { customRender: "expireAt" },
          align: "center",
          width: 170,
        },
        {
          title: "上次修改时间",
          ellipsis: true,
          dataIndex: "gmtModified",
          align: "center",
          width: 170,
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
          align: "center",
          width: 170,
        },
      ],
      fileCollectDetailTableColumns: [
        {
          title: "编号",
          ellipsis: true,
          dataIndex: "id",
          width: 90,
          align: "center",
        },
        {
          title: "文件名称",
          ellipsis: true,
          dataIndex: "name",
          scopedSlots: { customRender: "name" },
          width: 300,
        },
        {
          title: "文件大小",
          ellipsis: true,
          dataIndex: "fileSize",
          align: "center",
          width: 130,
        },
        {
          title: "备注",
          ellipsis: true,
          dataIndex: "remark",
        },
        {
          title: "过期时间",
          ellipsis: true,
          dataIndex: "expireAt",
          scopedSlots: { customRender: "expireAt" },
          align: "center",
          width: 170,
        },
        {
          title: "上次修改时间",
          ellipsis: true,
          dataIndex: "gmtModified",
          align: "center",
          width: 170,
        },
        {
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action", title: "actionTitle" },
          width: 240,
        },
      ],
      tableData: [],
      tablePagination: {},
      loadingTable: false,
      pageNo: 1,
      pageSize: 10,
      dialogVisible: false,
      dialogVisible2: false,
      fileList: [],
      fileNameAdd: "",
      syncLoading: null,
      active: "",
      fileCollectPageNo: 1,
      fileCollectTableColumns: [
        {
          title: "编号",
          ellipsis: true,
          dataIndex: "id",
          width: 90,
          align: "center",
        },
        {
          title: "名称",
          ellipsis: true,
          dataIndex: "name",
          width: 200,
        },
        {
          title: "文件数量",
          ellipsis: true,
          dataIndex: "fileNum",
          align: "center",
          width: 170,
        },
        {
          title: "文件有效天数",
          ellipsis: true,
          dataIndex: "expireDays",
          scopedSlots: { customRender: "expireDays" },
          align: "center",
          width: 170,
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
          align: "center",
          width: 170,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 200,
        },
      ],
      fileCollectTableData: [],
      fileCollectTablePagination: {},
      fileCollectLoadingTable: false,
      fileCollectName: "",
      fileCollectTitle: "",
      fileCollectVisible: false,
      fileCollectNameAdd: "",
      expireDays: "",
      collectId: "",
      detailTitle: "",
      fileCollectDetailVisible: false,
      addFileListShow: false,
      fileIdList: [],
      pipelineSourceFileId: "",
      expirable: false,
      expireAt: "",
      originalExpireAt: "",
      modifyExpireAtShow: false,
      batchModifyExpireAtFileList: [],
      previewFileId: undefined,
      batchModifyExpireAtShow: false,
      previewBatchModifyExpireAtShow: false,
      previewBatchModifyExpireAtTableData: [],
      previewBatchModifyExpireAtTableColumns: [
        {
          title: "编号",
          ellipsis: true,
          dataIndex: "id",
          width: 90,
          align: "center",
        },
        {
          title: "文件名称",
          ellipsis: true,
          dataIndex: "name",
        },
        {
          title: "过期时间",
          ellipsis: true,
          dataIndex: "expireAt",
          scopedSlots: { customRender: "expireAt" },
          align: "center",
          width: 210,
        },
      ],
      fileType: "txt",
      checkFileContentShow: false,
      fileContent: "",
      excelFileList: [],
      excelFileColumns: [],
      queryRestoreStatusTimer: null,
    };
  },
  watch: {
    active: {
      handler(newVal) {
        this.name = "";
        this.pageNo = 1;
        this.pageSize = 10;
        this.fileIdList.splice(0);
        if (newVal === "1") {
          this.fileQuery(1);
        } else if (newVal === "2") {
          this.fileCollectQuery(1);
        }
        sessionStorage.setItem("active", newVal);
      },
    },
  },
  created() {
    let active = sessionStorage.getItem("active");
    if (active) {
      this.active = active;
    } else {
      this.active = "1";
    }
  },
  methods: {
    fileCollectAdd() {
      this.fileCollectTitle = "新增";
      this.collectId = "";
      this.expireDays = 180;
      this.fileCollectNameAdd = "";
      this.fileCollectVisible = true;
    },
    fileCollectEdit(val) {
      this.fileCollectTitle = "修改";
      this.collectId = val.id;
      this.expireDays = val.expireDays;
      this.fileCollectNameAdd = val.name;
      this.fileCollectVisible = true;
    },
    updateFileCollect_submit() {
      let data = {
        name: this.fileCollectNameAdd,
        expireSecond:
          this.expireDays > 0 ? this.expireDays * 86400 : this.expireDays,
      };
      if (this.fileCollectTitle == "新增") {
        this.$http.json_post("/fileCollect/addCollect", data).then((res) => {
          if (res.result === 200) {
            this.$message.success("新增成功");
            this.fileCollectVisible = false;
            this.fileCollectQuery(1);
          }
        });
      } else {
        data.id = this.collectId;
        this.$http.json_post("/fileCollect/editCollect", data).then((res) => {
          if (res.result === 200) {
            this.$message.success("修改成功");
            this.fileCollectVisible = false;
            this.fileCollectQuery();
          }
        });
      }
    },
    fileCollectDelete(val) {
      let data = {
        id: val.id,
      };
      this.$http.json_post("/fileCollect/deleteCollect", data).then((res) => {
        if (res.result === 200) {
          this.$message.success("删除成功");
          this.fileCollectQuery();
        }
      });
    },
    allowClearFileCollectQueryChange(e) {
      if (e.target.value) {
        return;
      }
      this.fileCollectQuery(1);
    },
    fileCollectQuery(index) {
      this.fileCollectLoadingTable = true;
      if (index) {
        this.fileCollectPageNo = index;
      }
      let data = {
        pageNo: this.fileCollectPageNo,
        pageSize: 10,
        name: this.fileCollectName,
      };
      this.$http.get("/fileCollect/collectPage", data).then((res) => {
        if (res.result === 200) {
          this.fileCollectTableData = res.data.records;
          this.fileCollectTablePagination = {
            showQuickJumper: true,
            showTotal: () => `共${res.data.total}条`,
            pageSize: res.data.pageSize,
            current: res.data.pageNo,
            total: res.data.total,
            onChange: (current) => this.changeFileCollectPageNo(current),
          };
          this.fileCollectLoadingTable = false;
        }
      });
    },
    // 翻页
    changeFileCollectPageNo(index) {
      this.fileCollectQuery(index);
    },
    downloadLocalFile(val) {
      let href =
        location.protocol +
        process.env.VUE_APP_BASE_API +
        "/pipelineSourceFile/download?id=" +
        val.id;
      location.href = href;
    },
    modifyExpireAt(val) {
      this.pipelineSourceFileId = val.id;
      this.expirable = val.expirable;
      this.originalExpireAt = val.expireAt;
      this.expireAt = val.expireAt;
      this.modifyExpireAtShow = true;
    },
    expirableChange() {
      if (this.expirable) {
        this.expireAt = moment().format("YYYY-MM-DD HH:mm:ss");
      } else {
        this.expireAt = this.originalExpireAt;
      }
    },
    modifyExpireAtSubmit() {
      if (this.previewBatchModifyExpireAtShow) {
        this.previewBatchModifyExpireAtTableData.forEach((item) => {
          if (item.id == this.pipelineSourceFileId) {
            this.$set(item, "expirable", this.expirable);
            this.$set(item, "expireAt", this.expireAt);
          }
        });
        this.$message.success("修改成功");
        this.modifyExpireAtShow = false;
      } else {
        let data = {
          pipelineSourceFileId: this.pipelineSourceFileId,
          expirable: this.expirable,
          expireAt: this.expireAt,
        };

        this.$http
          .json_post("/pipelineSourceFile/modifyExpireDate", data)
          .then((res) => {
            if (res.result == 200) {
              this.$message.success("修改成功");
              this.modifyExpireAtShow = false;
              this.fileQuery();
            }
          });
      }
    },
    add() {
      this.dialogVisible = true;
    },
    create() {
      this.dialogVisible2 = true;
    },
    create_submit() {
      let data = {
        fileName: this.fileNameAdd,
      };
      this.$http
        .json_post("/pipelineSourceFile/createEmptyPipelineSourceFile", data)
        .then((res) => {
          if (res.result == 200) {
            this.$message.success("创建成功");
            this.dialogVisible2 = false;
            this.fileQuery();
          }
        });
    },
    beforeUpload(file) {
      this.fileList = [...this.fileList, file];
      return false;
    },
    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
      this.$message.success("删除成功");
    },
    add_submit() {
      if (this.fileList.length == 0) {
        this.$message.success({
          type: "info",
          message: "请先上传文件！",
        });
        return;
      }
      let data = {
        fileNameList: this.fileList.map((item) => {
          return item.name;
        }),
      };
      this.$http
        .json_post("/pipelineSourceFile/checkRepeatedFileName", data)
        .then((res) => {
          if (res.result == 200) {
            if (res.data.length > 0) {
              this.$message.warning(
                res.data.join(" , ") + "名称重复,请修改名称"
              );
            } else {
              this.$axios
                .all(
                  this.fileList.map((file) => {
                    let data = {
                      file,
                    };
                    return this.$http.files(
                      "/pipelineSourceFile/addPipelineSourceFile",
                      data
                    );
                  })
                )
                .then((resArr) => {
                  this.dialogVisible = false;
                  this.fileQuery(1);
                  this.fileList.splice(0);
                  this.$message.success("上传成功");
                });
            }
          }
        });
    },
    closed() {
      this.fileList.splice(0);
    },
    // 修改
    updateFile(val) {
      this.cronTaskShow = true;
      this.nameUp = val.name;
      this.remarkUp = val.remark;
      this.expirable = val.expirable;
      this.originalExpireAt = val.expireAt;
      this.expireAt = val.expireAt;
      this.id = val.id;
    },
    empty(val) {
      let data = {
        id: val.id,
      };
      this.$http
        .json_post("/pipelineSourceFile/emptyPipelineSourceFile", data)
        .then((res) => {
          if (res.result === 200) {
            this.syncLoading.close();
            this.$message.success("清空成功");
            this.fileQuery();
          }
        });
    },
    uploadOss(val) {
      let data = {
        id: val.id,
      };
      this.syncLoading = this.$syncLoading({
        message: "上传中...",
      });
      this.$http
        .json_post("/pipelineSourceFile/upload2Oss", data)
        .then((res) => {
          if (res.result === 200) {
            this.syncLoading.close();
            this.$message.success("上传成功");
            this.fileQuery();
          }
        })
        .catch(() => {
          this.syncLoading.close();
        });
    },
    downloadLocal(val) {
      let data = {
        id: val.id,
      };
      this.syncLoading = this.$syncLoading({
        message: "下载中...",
      });
      this.$http
        .json_post("/pipelineSourceFile/download2Local", data)
        .then((res) => {
          if (res.result === 200) {
            this.syncLoading.close();
            this.$message.success("下载成功");
            this.fileQuery();
          }
        })
        .catch(() => {
          this.syncLoading.close();
        });
    },
    batchRefresh() {
      this.syncLoading = this.$syncLoading({
        message: "请稍等...",
      });
      this.$axios
        .all(
          this.fileIdList.map((id) => {
            let data = {
              id,
            };
            return this.$http.json_post(
              "/pipelineSourceFile/pipelineSourceFileSize",
              data
            );
          })
        )
        .then(() => {
          this.fileIdList.splice(0);
          this.syncLoading.close();
          this.$message.success("刷新成功");
          this.fileQuery();
        });
    },
    batchDelete() {
      this.$confirm({
        title: "提示",
        content: (h) => <div style="color:red;">确定要删除吗?</div>,
        okText: "确定",
        cancelText: "取消",
        onOk: () => {
          this.$axios
            .all(
              this.fileIdList.map((id) => {
                let data = {
                  id,
                };
                return this.$http.json_post(
                  "/pipelineSourceFile/deletePipelineSourceFile",
                  data
                );
              })
            )
            .then(() => {
              this.fileIdList.splice(0);
              this.$message.success("删除成功");
              this.fileQuery();
            });
        },
        onCancel: () => {},
      });
    },
    checkFileContent(val) {
      this.fileContent = "";
      this.excelFileList.splice(0);
      this.excelFileColumns.splice(0);
      this.fileType = val.name.split(".")[1];
      this.syncLoading = this.$syncLoading({
        message: "获取文件内容中...",
      });
      let id = val.id;
      this.$http
        .get("/pipelineFile/checkFileContent", {
          id,
          type: "sourceFileId",
        })
        .then((res) => {
          if (res.result == 200) {
            this.syncLoading.close();
            if (res.data.state == "ARCHIVED") {
              this.$confirm({
                title: "文件已归档，查看需要进行解冻",
                content: (h) => <div style="color:red;">确认解冻吗</div>,
                okText: "确定",
                cancelText: "取消",
                onOk: () => {
                  this.restore(id);
                },
                onCancel: () => {},
              });
            } else if (res.data.state == "SUCCESS") {
              if (this.fileType == "xlsx" || this.fileType == "xls") {
                res.data.data.forEach((item, index) => {
                  let obj = {
                    id: index + 1,
                  };
                  item.forEach((value, key) => {
                    obj["column" + (key + 1)] = value;
                  });
                  this.excelFileList.push(obj);
                });

                res.data.data[0].forEach((value, index) => {
                  this.excelFileColumns.push({
                    title: "列" + (index + 1),
                    ellipsis: true,
                    dataIndex: "column" + (index + 1),
                    width: 100,
                  });
                });
              } else {
                this.fileContent = res.data.data[0].join("\n");
              }
              this.checkFileContentShow = true;
            }
          }
        })
        .catch((err) => {
          this.syncLoading.close();

          if (
            err.response.data.result == 400 &&
            err.response.data.message == "文件已归档"
          ) {
            this.$confirm({
              title: "文件已归档，查看需要进行解冻",
              content: (h) => <div style="color:red;">确认解冻吗</div>,
              okText: "确定",
              cancelText: "取消",
              onOk: () => {
                this.restore(id);
              },
              onCancel: () => {},
            });
          }
        });
    },
    restore(id) {
      this.syncLoading = this.$syncLoading({
        message: "解冻中...",
      });
      this.$http
        .post("/pipelineFile/restore", {
          id,
          type: "sourceFileId",
        })
        .then((res) => {
          if (res.result == 200) {
            this.queryRestoreStatus(id);
          }
        })
        .catch(() => {
          this.syncLoading.close();
        });
    },
    queryRestoreStatus(id) {
      this.$http
        .get("/pipelineFile/queryRestoreStatus", {
          id,
          type: "sourceFileId",
        })
        .then((res) => {
          if (res.result == 200) {
            if (res.data !== "RUNNING") {
              clearTimeout(this.queryRestoreStatusTimer);
              this.queryRestoreStatusTimer = null;
              this.syncLoading.close();
              if (res.data == "DONE") {
                this.$message.success("解冻成功");
              } else {
                this.$message.error("解冻失败");
              }
            } else {
              this.queryRestoreStatusTimer = setTimeout(() => {
                this.queryRestoreStatus(id);
              }, 1000);
            }
          }
        })
        .catch(() => {
          this.syncLoading.close();
        });
    },
    refresh(val) {
      this.syncLoading = this.$syncLoading({
        message: "请稍等...",
      });
      let data = {
        id: val.id,
      };
      this.$http
        .json_post("/pipelineSourceFile/pipelineSourceFileSize", data)
        .then((res) => {
          if (res.result === 200) {
            this.syncLoading.close();
            this.$message.success("刷新成功");
            this.fileQuery();
          }
        });
    },
    // 确定修改
    cronTask_submit() {
      let data = {
        id: this.id,
        name: this.nameUp,
        remark: this.remarkUp,
        expirable: this.expirable,
        expireAt: this.expireAt,
      };
      this.$http
        .json_post("/pipelineSourceFile/editPipelineSourceFile", data)
        .then((res) => {
          if (res.result === 200) {
            this.$message.success("修改成功");
            this.cronTaskShow = false;
            this.fileQuery(null, null, this.collectId);
          }
        });
    },
    // 删除
    deleteFile(val) {
      let data = {
        id: val.id,
      };
      this.$http
        .json_post("/pipelineSourceFile/deletePipelineSourceFile", data)
        .then((res) => {
          if (res.result === 200) {
            this.$message.success("删除成功");
            this.fileQuery();
          }
        });
    },
    removeFromCollect(val) {
      let data = {
        pipelineSourceFileId: val.id,
        collectId: this.collectId,
      };
      this.$http
        .json_post("/fileCollect/removeFromCollect", data)
        .then((res) => {
          if (res.result === 200) {
            this.$message.success("删除成功");
            this.fileQuery(1, 10, this.collectId);
          }
        });
    },
    addFileListClose() {
      this.name = "";
      if (this.fileCollectDetailVisible) {
        this.fileQuery(1, 10, this.collectId);
      } else {
        this.collectId = "";
      }
    },
    addFileList() {
      this.addFileListShow = true;
      this.fileIdList.splice(0);
      this.fileQuery(1);
    },
    addFileList_submit() {
      let data = {
        id: this.collectId,
        fileIdList: this.fileIdList,
      };
      this.$http.json_post("/fileCollect/addFile", data).then((res) => {
        if (res.result === 200) {
          this.$message.success("添加成功");
          this.fileQuery(1);
          this.addFileListShow = false;
        }
      });
    },
    batchModifyExpireAt() {
      let data = {
        pageNo: 1,
        pageSize: this.tablePagination.total,
        collectId: this.collectId,
      };
      this.$http
        .get("/pipelineSourceFile/pipelineSourceFilePage", data)
        .then((res) => {
          if (res.result === 200) {
            this.batchModifyExpireAtFileList = res.data.records;
            this.previewFileId = undefined;
            this.batchModifyExpireAtShow = true;
          }
        });
    },
    previewBatchModifyExpireAt() {
      let data = {
        collectId: this.collectId,
        id: this.previewFileId,
      };

      this.$http
        .get("/pipelineSourceFile/previewBatchUpdateExpireAt", data)
        .then((res) => {
          if (res.result === 200) {
            this.previewBatchModifyExpireAtTableData = res.data;
            this.previewBatchModifyExpireAtShow = true;
          }
        });
    },
    batchModifyExpireAtSubmit() {
      this.syncLoading = this.$syncLoading({
        message: "请稍等...",
      });
      this.$axios
        .all(
          this.previewBatchModifyExpireAtTableData.map((item) => {
            let data = {
              pipelineSourceFileId: item.id,
              expirable: item.expirable,
              expireAt: item.expireAt,
            };
            return this.$http.json_post(
              "/pipelineSourceFile/modifyExpireDate",
              data
            );
          })
        )
        .then(() => {
          this.previewBatchModifyExpireAtTableData.splice(0);
          this.syncLoading.close();
          this.$message.success("修改成功");
          this.previewBatchModifyExpireAtShow = false;
          this.batchModifyExpireAtShow = false;
        });
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.fileIdList.splice(0);
      this.fileIdList.push(...selectedRowKeys);
    },
    allowClearFileQueryChange(e, collectId) {
      if (e.target.value) {
        return;
      }
      this.fileQuery(1, 10, collectId);
    },
    // 查询
    fileQuery(index, size, collectId, name) {
      this.loadingTable = true;
      if (index) {
        this.pageNo = index;
      }
      if (size) {
        this.pageSize = size;
      }
      if (collectId) {
        this.collectId = collectId;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        name: this.name,
        collectId,
      };
      if (name) {
        this.detailTitle = name + "详情";
      }
      this.$http
        .get("/pipelineSourceFile/pipelineSourceFilePage", data)
        .then((res) => {
          if (res.result === 200) {
            this.tableData = res.data.records;
            this.tablePagination = {
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: res.data.pageSize,
              current: res.data.pageNo,
              total: res.data.total,
              pageSizeOptions: ["10", "20", "50", "100"],
              onChange: (current, size) =>
                this.fileQuery(current, size, collectId, name),
              onShowSizeChange: (current, size) =>
                this.fileQuery(current, size, collectId, name),
            };
            if (collectId) {
              this.fileCollectDetailVisible = true;
            }
            this.loadingTable = false;
          }
        });
    },
  },
};
</script>
<style scoped lang="scss">
.slotCell {
  &:hover {
    .copy-class {
      display: inline-block;
    }
  }
  .copy-class {
    color: #52c41a;
    display: none;
  }
}
</style>
